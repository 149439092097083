var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1000px" },
          on: { "click:outside": _vm.emitClose },
          model: {
            value: _vm.secureCredentialAccessDialog,
            callback: function($$v) {
              _vm.secureCredentialAccessDialog = $$v
            },
            expression: "secureCredentialAccessDialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { loading: _vm.isLoading } },
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v(_vm._s(_vm.formTitle))
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.validForm,
                        callback: function($$v) {
                          _vm.validForm = $$v
                        },
                        expression: "validForm"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pb-2" },
                        [
                          _c("v-col", [
                            _c("div", { staticClass: "subtitle-1" }, [
                              _vm._v(
                                " DAMM generates passwords for users created as part of a Job. With this form, you can generate a URL to send to an authorized admin user from a Five9 Domain. With that URL, the user can access a CSV of all the usernames and passwords loaded in this Job, as long as they correctly authenticate with the user account in the username field below. "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm.editing
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "api-key",
                                    attrs: {
                                      rows: "2",
                                      readonly: "",
                                      value: _vm.shortenAccessUrl(
                                        _vm.formData.accessUrl
                                      ),
                                      label: "Access URL: "
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color:
                                                      _vm.clipboard.accessUrl
                                                        .color
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.copyToClipboard(
                                                        _vm.formData.accessUrl,
                                                        "Access URL Copied",
                                                        _vm.clipboard.accessUrl
                                                          .id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("mdi-clipboard-text")]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3657185769
                                    )
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "api-key",
                                    attrs: {
                                      rows: "7",
                                      readonly: "",
                                      label: "Access Code: "
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color:
                                                      _vm.clipboard.accessCode
                                                        .color
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.copyToClipboard(
                                                        _vm.formData.accessCode,
                                                        "Access Code Copied",
                                                        _vm.clipboard.accessCode
                                                          .id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("mdi-clipboard-text")]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      2538601289
                                    ),
                                    model: {
                                      value: _vm.formData.accessCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "accessCode",
                                          $$v
                                        )
                                      },
                                      expression: "formData.accessCode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "my-0 py-0" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            "Email Address to send auth link to",
                                          placeholder:
                                            "misuta.roboto@five9.com",
                                          dense: "",
                                          outlined: "",
                                          "prepend-icon": "mdi-email",
                                          disabled: _vm.editing,
                                          rules: _vm.rules.email
                                        },
                                        model: {
                                          value: _vm.formData.email,
                                          callback: function($$v) {
                                            _vm.$set(_vm.formData, "email", $$v)
                                          },
                                          expression: "formData.email"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  !_vm.editing
                                    ? _c(
                                        "v-col",
                                        { staticClass: "my-0 py-0" },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                "Five9 Username for auth link recipient",
                                              placeholder: "mrroboto_vcc_admin",
                                              dense: "",
                                              outlined: "",
                                              "prepend-icon": "mdi-account",
                                              rules: _vm.rules.required
                                            },
                                            model: {
                                              value:
                                                _vm.formData.usernameToMatch,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "usernameToMatch",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formData.usernameToMatch"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.editing
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "my-0 py-0",
                                          attrs: { cols: "3" }
                                        },
                                        [
                                          _c("v-select", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              outlined: "",
                                              "hide-details": "",
                                              dense: "",
                                              placeholder: "Domain Location",
                                              label: "Domain Location",
                                              rules: _vm.rules.required,
                                              "prepend-icon": "mdi-earth",
                                              items: _vm.Five9RegionList
                                            },
                                            model: {
                                              value: _vm.formData.region,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "region",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.region"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _vm.editing
                                    ? _c(
                                        "v-col",
                                        { staticClass: "my-0 py-0" },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                "Five9 Username for auth link recipient",
                                              placeholder:
                                                "mrroboto_vcc_admin@five9.com",
                                              dense: "",
                                              outlined: "",
                                              "prepend-icon": "mdi-account",
                                              disabled: _vm.editing,
                                              rules: _vm.rules.required
                                            },
                                            model: {
                                              value:
                                                _vm.formData.usernameToMatch,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "usernameToMatch",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formData.usernameToMatch"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.editing
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "my-0 py-0" },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "activate mx-1",
                                                  attrs: {
                                                    small: "",
                                                    color: _vm.statusColor
                                                  }
                                                },
                                                [_vm._v("mdi-circle")]
                                              ),
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass: "mx-2",
                                                  attrs: {
                                                    "close-icon":
                                                      "mdi-close-outline",
                                                    outlined: "",
                                                    pill: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.statusText) + " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.editing
                            ? _c(
                                "v-col",
                                { staticClass: "my-0 py-0" },
                                [
                                  _vm.hasJustStarted && !_vm.hasSentEmail
                                    ? _c("div", [
                                        _vm._v(
                                          " Once you click the 'Send Email' button, an email will be sent to the recipient at the address specified in the Email field. The email will contain the URL needed to access the agent passwords loaded with this Job. "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.hasJustStarted && !_vm.hasSentEmail
                                    ? _c("v-checkbox", {
                                        attrs: { label: "Agree and Continue" },
                                        model: {
                                          value: _vm.hasAgreedToInstructions,
                                          callback: function($$v) {
                                            _vm.hasAgreedToInstructions = $$v
                                          },
                                          expression: "hasAgreedToInstructions"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.hasSentEmail
                                    ? _c(
                                        "div",
                                        { staticClass: "font-weight-regular" },
                                        [
                                          _vm._v(
                                            " The url will bring the user to a sign on page, where they will be able to download a password protected file containing the new agent usernames and passwords for the destination Five9 Domain. The file password is the "
                                          ),
                                          _c("b", [_vm._v("Access Code")]),
                                          _vm._v(" on this form. ")
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", outlined: "", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancel()
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c("v-spacer"),
                  _vm.editing
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.validForm || _vm.isLoading,
                            color: "error",
                            outlined: "",
                            text: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.deleteWithConfirmation(_vm.formData)
                            }
                          }
                        },
                        [_vm._v(" Delete ")]
                      )
                    : _vm._e(),
                  _vm.editing
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled:
                              !_vm.hasAgreedToInstructions ||
                              _vm.hasSentEmail ||
                              _vm.isLoading,
                            color: "green darken-1",
                            outlined: "",
                            text: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.sendCredentialAccessEmail(_vm.formData)
                            }
                          }
                        },
                        [_vm._v(" Email the Credential Access URL ")]
                      )
                    : _vm._e(),
                  !_vm.editing
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.isLoading,
                            color: "green darken-1",
                            outlined: "",
                            text: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.createOrUpdate(_vm.formData)
                            }
                          }
                        },
                        [_vm._v("Create ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("confirm")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }