









































































































































































































import Vue from 'vue';
import { defineComponent, reactive, toRefs, computed, ref } from '@vue/composition-api';
import Confirm, { useConfirmation } from '@/components/confirmation/Confirmation.vue';
import {
  useSecureCredentialAccess,
  SecureCredentialAccessModel
} from '@/module/api/secure-credential-access';
import { createToastInterface } from 'vue-toastification';
import { truncateUrl } from '@/utils';
import {
  ClipboardIconName,
  ClipboardIconColors,
  SecureCredentialAccessStateName
} from './secure-credential-access.interface';
import { useLoadJobApi } from '@/module/api/load-job';
import { Five9RegionList } from '@/module/api/domain';
import { UserMessageError } from '@/lib/user-message-error';

const { items: domoSetLoadJobs, getItem: getLoadJob } = useLoadJobApi();

const { open } = useConfirmation();

const toast = createToastInterface({ maxToasts: 3 });

const {
  emptyItem,
  createItem,
  updateItem,
  selectedItem,
  deleteItem,
  refreshItem,
  isLoading: isSecureAccessLoading,
  sendAccessUrlEmailByItemUuid
} = useSecureCredentialAccess();

const emptyState = {
  secureCredentialAccessDialog: false,
  validForm: false,
  errorMessage: '',
  formTitle: 'New Secure Credential Access URL',
  editing: false,
  displayItem: false,
  clipboard: {
    accessUrl: {
      color: ClipboardIconColors.DEFAULT,
      id: 'accessUrl' as ClipboardIconName
    },
    accessCode: {
      color: ClipboardIconColors.DEFAULT,
      id: 'accessCode' as ClipboardIconName
    }
  },
  hasJustStarted: true,
  hasAgreedToInstructions: false,
  hasSentEmail: false,
  formData: { ...emptyItem.value } as SecureCredentialAccessModel
};

const isLoading = ref(false);

const state = reactive({ ...emptyState });

const statusText = computed(() => {
  const objectState = state.formData.state;
  const defaultText = 'Access Url Generated';
  if (objectState == SecureCredentialAccessStateName.NEW) {
    return defaultText;
  }
  if (objectState == SecureCredentialAccessStateName.SENT) {
    return 'Access URL Has Been Sent';
  }
  if (objectState == SecureCredentialAccessStateName.CLICKED) {
    return 'Access Url Has Been Clicked';
  }
  if (objectState == SecureCredentialAccessStateName.RETRIEVED) {
    return 'Document Has Been Retrieved';
  }
  return defaultText;
});

const statusColor = computed(() => {
  const objectState = state.formData.state;
  const defaultColor = 'light';
  if (objectState == SecureCredentialAccessStateName.NEW) {
    return defaultColor;
  }
  if (objectState == SecureCredentialAccessStateName.SENT) {
    return 'blue darken-1';
  }
  if (objectState == SecureCredentialAccessStateName.CLICKED) {
    return 'teal darken-1';
  }
  if (objectState == SecureCredentialAccessStateName.RETRIEVED) {
    return 'green darken-1';
  }
  return defaultColor;
});

const resetClipboardStyle = (id: ClipboardIconName) => {
  state.clipboard[id].color = ClipboardIconColors.DEFAULT;
};

const triggerClipboardCopyStates = (id: ClipboardIconName) => {
  state.clipboard[id].color = ClipboardIconColors.CLICKED;
  setTimeout(() => {
    state.clipboard[id].color = ClipboardIconColors.DEFAULT;
  }, 200);
};

const copyToClipboard = (valueToCopy: any, alertText: string, id: ClipboardIconName): void => {
  navigator.clipboard.writeText(valueToCopy);
  triggerClipboardCopyStates(id);
  toast.success(alertText);
};

const rules = {
  required: [(v: string) => !!v || 'Required.'],
  email: [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ]
};

const reset = () => {
  Object.assign(state, { ...emptyState });
};

const cancel = () => {
  reset();
};

const refreshForCreate = async (loadJobUuid: string) => {
  Object.assign(state.formData, {
    ...emptyItem.value,
    domoSetLoadJobUuid: loadJobUuid,
    label: `secure-credential-access-created-from-ui-for-${loadJobUuid}`
  });
  state.editing = false;
  state.formTitle = 'New Secure Credential Access URL';
  state.validForm = false;
};

const refreshForUpdate = async (uuid: string) => {
  await refreshItem(uuid, true);
  Object.assign(state.formData, {
    ...selectedItem.value
  });
  state.editing = true;
  state.formTitle = 'Edit Secure Credential Access URL';
  state.validForm = false;
};

const createOrUpdate = async (item: SecureCredentialAccessModel): Promise<void> => {
  isLoading.value = true;
  try {
    if (state.editing) {
      const params: Partial<SecureCredentialAccessModel> = {
        domoSetLoadJobUuid: item.domoSetLoadJobUuid,
        usernameToMatch: item.usernameToMatch,
        email: item.email,
        label: item.label,
        region: item.region
      };
      const updated = await updateItem(params);
      Vue.$log.debug('Updated item.', updated);
      await refreshForUpdate(updated.uuid);
    } else {
      const params: Partial<SecureCredentialAccessModel> = {
        domoSetLoadJobUuid: item.domoSetLoadJobUuid,
        usernameToMatch: item.usernameToMatch,
        email: item.email,
        label: item.label,
        region: item.region
      };
      const created = await createItem(params);
      // We need to refresh the load job so it knows we have it.
      await getLoadJob(item.domoSetLoadJobUuid);
      Vue.$log.debug('Saved new item.', created);
      await refreshForUpdate(created.uuid);
    }
  } catch (e) {
    if (e instanceof UserMessageError) {
      toast.error(e.message);
    } else {
      toast.error('Form submission failed.');
    }
  } finally {
    isLoading.value = false;
  }
};

const deleteWithConfirmation = async (item: SecureCredentialAccessModel) => {
  open({
    message: 'Delete the secure credential access url?'
  }).then(async (confirmed: boolean) => {
    if (confirmed) {
      isLoading.value = true;
      try {
        await deleteItem(item.uuid);
        // We need to refresh the load job so it knows we no longer have it.
        await getLoadJob(item.domoSetLoadJobUuid);
        // Clear and close the form.
        reset();
      } catch (e) {
        Vue.$log.error('Error deleting', e);
      } finally {
        isLoading.value = false;
      }
    }
  });
};

const showSecureCredentialAccessCreateForm = async (domoSetLoadJobUuid: string) => {
  await refreshForCreate(domoSetLoadJobUuid);
  state.secureCredentialAccessDialog = true;
  Vue.$log.debug('Showing create form. Current state: ', state);
};

const showSecureCredentialAccessEditForm = async (uuid: string) => {
  await refreshForUpdate(uuid);
  state.secureCredentialAccessDialog = true;
  Vue.$log.debug('Showing edit form. Current state: ', state);
};

const showSecureCredentialAccessForm = async (domoSetLoadJob: any) => {
  if (domoSetLoadJob.authBridgeUuid && domoSetLoadJob.authBridgeUuid !== '') {
    await showSecureCredentialAccessEditForm(domoSetLoadJob.authBridgeUuid);
  } else {
    await showSecureCredentialAccessCreateForm(domoSetLoadJob.uuid);
  }
};

const sendCredentialAccessEmail = async (item: SecureCredentialAccessModel) => {
  isLoading.value = true;
  try {
    const res = await sendAccessUrlEmailByItemUuid(item.uuid);
    if (res) {
      toast.success(`Sent Email to ${item.email}`);
      state.hasSentEmail = true;
      reset();
    } else {
      toast.error('Sending email failed.');
    }
  } catch (err) {
    Vue.$log.error(err);
    toast.error('Unable to send email.');
  } finally {
    isLoading.value = false;
  }
};

export function useSecureCredentialAccessForm() {
  return { showSecureCredentialAccessForm, selectedItem };
}

export default defineComponent({
  name: 'SecureCredentialAccessForm',
  components: { Confirm },
  emits: ['closed:secure-credential-access-form'],

  setup(props, context) {
    const emitClose = () => {
      context.emit('closed:secure-credential-access-form');
    };

    return {
      ...toRefs(state),
      createOrUpdate,
      rules,
      cancel,
      domoSetLoadJobs,
      isLoading: computed(() => isLoading.value || isSecureAccessLoading.value),
      deleteWithConfirmation,
      sendCredentialAccessEmail,
      copyToClipboard,
      resetClipboardStyle,
      statusText,
      statusColor,
      Five9RegionList,
      emitClose,
      shortenAccessUrl: (accessUrl: string | undefined): string => {
        if (!accessUrl) {
          return '';
        }
        return truncateUrl(accessUrl, 70);
      }
    };
  }
});
