






































































































































































































































































































































































































































































































import Confirm, { useConfirmation } from '@/components/confirmation/Confirmation.vue';
import JobDurationText from '@/components/job-duration-text/JobDurationText.vue';
import JobStatusIcon from '@/components/job-status-icon/JobStatusIcon.vue';
import LoadJobReportsButton from '@/components/load-job-reports-button/LoadJobReportsButton.vue';
import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';
import RefreshButton from '@/components/navigation/RefreshButton.vue';
import { BAD_FIVE9_VERSION_MESSAGE } from '@/lib/constants';
import { DomainModel, useDomainApi } from '@/module/api/domain';
import { DOMO_EXPIRATION_TTL_DAYS, isModelExpired } from '@/module/api/domo';
import { ExportModel, ExportStateName, getTotalObjects, useExportApi } from '@/module/api/export';
import {
LoadJobStateName,
LoadJobType,
ValidationSummaryCountType,
getFriendlyLoadJobTypeName,
getJobProgressLabel,
getJobProgressPercentCompletion,
getLastValidationJobSummaryCount,
getTotalOperations,
getTotalOperationsFailed,
getTotalOperationsSucceeded,
getTotalSuccessPercent,
isNewJobAndWizardIsNotComplete,
useLoadJobApi
} from '@/module/api/load-job';
import SecureCredentialAccessForm, {
useSecureCredentialAccessForm
} from '@/module/api/secure-credential-access/SecureCredentialAccessForm.vue';
import { useAuth, userCanUseTransformJobs } from '@/module/auth';
import router from '@/router';
import { formatDbDate, formatNumber, toNum } from '@/utils';
import DomoOperationChanger from '@/views/app/domo/DomoOperationChanger.vue';
import ImportJobDetailProvisioningComparison from '@/views/app/import/ImportJobDetailProvisioningComparison.vue';
import { computed, defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';

const { assertionContext } = useAuth();

const { open } = useConfirmation();

const {
  selectedItem: selectedDomain,
  getItem: getDomain,
  emptyDomainModel,
  isLoading: isDomainLoading,
  hasWritePermission,
  refreshItem: refreshDomain,
  getVersions
} = useDomainApi();

const {
  selectedItem: selectedLoadJob,
  isLoading: isLoadJobLoading,
  refreshItem: refreshLoadJob,
  cancelLoadJob
} = useLoadJobApi();

const {
  selectedItem: selectedExportJob,
  getItem: getExportJob,
  emptyExportModel: emptyExportJob,
  isLoading: isExportJobLoading,
  refreshItem: refreshExportJob
} = useExportApi();

const { showSecureCredentialAccessForm } = useSecureCredentialAccessForm();

const state = reactive({
  isManifestReportLoading: false,
  domainUuid: '',
  loadJobUuid: '',
  isCanceling: false,
  isSourceDomainMonolith: false,
  isDestinationDomainMonolith: false
});

const sourceExportJob = ref<ExportModel>(emptyExportJob());
const sourceDomain = ref<DomainModel>(emptyDomainModel());
const isOperationCurationStationDisclaimerAgreed = ref<boolean>(false);

const refreshSourceExportJob = async (uuid: string, forceRefresh = false): Promise<void> => {
  Vue.$log.debug(`Refreshing refreshSourceExportJob ${uuid} with force refresh ${forceRefresh}`);
  if (forceRefresh || sourceExportJob?.value?.uuid !== uuid) {
    const exportJob = await getExportJob(uuid);
    if (!exportJob) {
      sourceExportJob.value = emptyExportJob();
    }
    // TODO: why does a type guard not work here!?
    sourceExportJob.value = exportJob as any;
  }
};

const refreshSourceDomain = async (uuid: string, forceRefresh = false): Promise<void> => {
  Vue.$log.debug(`Refreshing refreshSourceDomain ${uuid} with force refresh ${forceRefresh}`);
  if (forceRefresh || sourceDomain?.value?.uuid !== uuid) {
    if (!uuid) {
      sourceDomain.value = emptyDomainModel();
    } else {
      const domain = await getDomain(uuid);
      if (!domain) {
        sourceDomain.value = emptyDomainModel();
      } else {
        sourceDomain.value = domain;
      }
    }
  }
};

const isJobOlderThanLatestJob = computed(() => {
  const mostRecentLoadJob = selectedDomain?.value?.mostRecentLoadJob;
  if (mostRecentLoadJob === undefined) {
    Vue.$log.debug('isJobOlderThanLatestJob: domain has no recent load job', selectedDomain?.value);
    return false;
  }
  if (mostRecentLoadJob.uuid === selectedLoadJob.uuid) {
    Vue.$log.debug(
      'isJobOlderThanLatestJob: most recent load job is same as the selected',
      selectedDomain?.value
    );
    return false;
  }
  const mostRecentLoadJobDateString = mostRecentLoadJob.updatedAt;
  if (mostRecentLoadJobDateString === undefined) {
    Vue.$log.debug(
      'isJobOlderThanLatestJob: no recent load job date, so false',
      selectedDomain?.value
    );
    return false;
  }
  const loadJobDateString = selectedLoadJob?.value?.updatedAt;
  if (loadJobDateString === undefined) {
    Vue.$log.debug(
      'isJobOlderThanLatestJob: no selected load job date, so false',
      selectedLoadJob.value
    );
    return false;
  }
  const mostRecentLoadJobDate = new Date(mostRecentLoadJobDateString);
  const loadJobDate = new Date(loadJobDateString);

  const isJobOlderThanLatestJob = loadJobDate < mostRecentLoadJobDate;
  Vue.$log.debug(
    `isJobOlderThanLatestJob: ${loadJobDateString} < ${mostRecentLoadJobDateString} = ${isJobOlderThanLatestJob}`,
    mostRecentLoadJob,
    selectedLoadJob.value
  );
  return isJobOlderThanLatestJob;
});

/**
 * Source = the state we want to get to
 * Destination = the state we are currently in
 *
 * Examples:
 *   - RESET: Source should be empty, Destination should have stuff to delete
 *   - IMPORT: Source should always have stuff, Destination should only have
 *     stuff if we are doing a sync import
 *   - RESTORE: Source should always have the restore point, Destination should
 *     have the current state of the Domain.
 */
const refreshItems = async (forceRefresh = true) => {
  Vue.$log.debug(`Refresh job detail with force of ${forceRefresh}`);
  if (!state.domainUuid || !state.loadJobUuid) {
    const errMessage = 'Unable to initialize table without valid domain and job uuid';
    Vue.$log.error(errMessage);
    return;
  }

  // We refresh the Domain for the workspace and the selected Load Job.
  await refreshDomain(state.domainUuid, forceRefresh);
  await refreshLoadJob(state.loadJobUuid, forceRefresh);

  // Source = the current domain, and the specified source extract job
  await refreshSourceDomain(selectedLoadJob.value?.source?.uuid, forceRefresh);
  await refreshSourceExportJob(selectedLoadJob.value?.source?.extractJobUuid, forceRefresh);

  // Destination = the selected destination
  // We already refreshed this domain.
  await refreshExportJob(selectedLoadJob.value?.destination?.extractJobUuid, forceRefresh);

  // Get versions.
  const sourceVersions = await getVersions(selectedLoadJob.value?.source?.uuid);
  state.isSourceDomainMonolith = sourceVersions && sourceVersions.isMonolith ? true : false;
  const destinationVersions = await getVersions(state.domainUuid);
  state.isDestinationDomainMonolith =
    destinationVersions && destinationVersions.isMonolith ? true : false;

  if (state.domainUuid !== selectedLoadJob.value?.domainUuid) {
    Vue.$log.error(
      `The domain ${state.domainUuid} does not match the Domain on the job ${selectedLoadJob.value?.domainUuid}`
    );
  }
};

const goToRunLoadJobWizard = () => {
  router.push({
    name: 'ImportJobRunWizard',
    params: {
      uuid: selectedDomain.value?.uuid,
      jobUuid: selectedLoadJob.value?.uuid
    }
  });
};

const confirmGoToRunLoadJobWizard = () => {
  if (
    selectedLoadJob.value?.mostRecentValidationJob?.validationSummary?.error &&
    toNum(selectedLoadJob.value?.mostRecentValidationJob?.validationSummary?.error) > 0
  ) {
    open({
      title: 'Proceed?',
      message:
        'Issues were identified for this job. Ensure you have reviewed those issues and addressed any fixable issues before proceeding. Do you wish to proceed?',
      options: {
        buttonOk: 'Yes',
        buttonCancel: 'No'
      }
    }).then(
      async (confirmed: boolean): Promise<void> => {
        if (confirmed) {
          goToRunLoadJobWizard();
        }
      }
    );
  } else {
    goToRunLoadJobWizard();
  }
};

const goToFixImportJobPage = () => {
  router.push({
    name: 'WorkspaceImportJobDetailFix',
    params: { uuid: selectedDomain.value?.uuid, jobUuid: selectedLoadJob.value?.uuid }
  });
};

const goToLoadJobOperationBrowserPage = () => {
  router.push({
    name: 'LoadJobOperationBrowser',
    params: {
      uuid: selectedDomain.value?.uuid,
      jobUuid: selectedLoadJob.value?.uuid
    }
  });
};

const goToTransformJobPage = () => {
  router.push({
    name: 'TransformJobBrowser',
    params: {
      uuid: selectedDomain.value?.uuid,
      jobUuid: selectedLoadJob.value?.uuid
    }
  });
};

const isAnyJobRunning = computed((): boolean => {
  if (selectedDomain?.value?.mostRecentExtractJob?.state === ExportStateName.IN_PROGRESS) {
    return true;
  }
  if (selectedDomain?.value?.mostRecentLoadJob?.state === LoadJobStateName.IN_PROGRESS) {
    return true;
  }
  return false;
});

/**
 * Tell me if the job COULD be run. And, we know we COULD run a job if:
 *   - we have permissions on the domain to do so
 *   - this job is NOT older than the latest job
 *   - this job is in READY state
 */
const isJobPermitted = computed(() => {
  if (!hasWritePermission(selectedDomain.value)) {
    return false;
  }
  if (isJobOlderThanLatestJob.value) {
    return false;
  }
  if (selectedLoadJob.value?.state !== LoadJobStateName.READY) {
    return false;
  }
  return true;
});

/**
 * The run button is disabled if the job is not permissible OR if there is some
 * other job currently running.
 */
const isRunButtonDisabled = computed(() => {
  if (!isJobPermitted.value) {
    return true;
  }
  if (isAnyJobRunning.value) {
    return true;
  }
  if (isModelExpired(selectedLoadJob.value)) {
    return true;
  }
  return false;
});

const runCancelLoadJob = async () => {
  state.isCanceling = true;
  open({
    title: 'Cancel Job?',
    message:
      'WARNING! Cancelling a job that is in progress will result in unspecified behavior on the domain being operated on. The cancellation may take a moment to take effect. Click Yes to cancel the job.',
    options: {
      buttonOk: 'Yes',
      buttonCancel: 'No'
    }
  })
    .then(
      async (confirmed: boolean): Promise<void> => {
        if (confirmed) {
          await cancelLoadJob(selectedLoadJob.value.uuid);
          await refreshItems(true);
        }
      }
    )
    .finally(() => {
      state.isCanceling = false;
    });
};

export default defineComponent({
  name: 'WorkspaceImportJobDetail',
  components: {
    RefreshButton,
    Confirm,
    DomainWorkspaceToolbar,
    DomoOperationChanger,
    JobStatusIcon,
    LoadJobReportsButton,
    SecureCredentialAccessForm,
    JobDurationText,
    ImportJobDetailProvisioningComparison
  },
  props: {
    uuid: {
      type: String
    },
    jobUuid: {
      type: String
    }
  },
  setup(props) {
    state.domainUuid = props?.uuid || '';
    state.loadJobUuid = props?.jobUuid || '';
    refreshItems(false);

    return {
      ...toRefs(state),
      isLoading: computed(() => {
        return isExportJobLoading.value || isLoadJobLoading.value || isDomainLoading.value;
      }),
      isJobOlderThanLatestJob,
      LoadJobStateName,
      formatDbDate,
      formatNumber,
      refreshItems,
      selectedDomain,
      selectedLoadJob,
      selectedExportJob,
      sourceDomain,
      getJobProgressLabel,
      getJobProgressPercentCompletion,
      getTotalOperationsSucceeded,
      getTotalOperationsFailed,
      getTotalSuccessPercent,
      getTotalOperations,
      getTotalObjects,
      confirmGoToRunLoadJobWizard,
      showSecureCredentialAccessForm,
      sourceExportJob,
      runCancelLoadJob,
      isJobPermitted,
      isRunButtonDisabled,
      isNewJobAndWizardIsNotComplete,
      getFriendlyLoadJobTypeName,
      LoadJobType,
      getLastValidationJobSummaryCount,
      ValidationSummaryCountType,
      goToLoadJobOperationBrowserPage,
      goToFixImportJobPage,
      goToTransformJobPage,
      uuidv4,
      DOMO_EXPIRATION_TTL_DAYS,
      isModelExpired,
      backLink: `/domain/${props.uuid}/workspace`,
      userCanUseTransformJobs,
      assertionContext,
      isOperationCurationStationDisclaimerAgreed,
      BAD_FIVE9_VERSION_MESSAGE
    };
  }
});
