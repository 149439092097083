import Vue from 'vue';
import axios from 'axios';
import { computed, reactive, ref } from '@vue/composition-api';
import {
  SecureCredentialAccessModel,
  SecureCredentialAccessStateName
} from './secure-credential-access.interface';
import { BaseItemState, BaseUseItemModel } from '../../shared/base.use-crud.interface';
import { AuthBridgeActionName } from '@/module/public-auth-bridge';
import { useAuth } from '@/module/auth';
import { isValid } from 'date-fns';
import _, { reject } from 'lodash';
import { Five9RegionName } from '@/module/api/domain';
import { UserMessageError } from '@/lib/user-message-error';

const emptyItem: SecureCredentialAccessModel = {
  domoSetLoadJobUuid: '',
  usernameToMatch: '',
  email: '',
  label: '',
  region: Five9RegionName.DEFAULT,
  state: SecureCredentialAccessStateName.NEW,
  authAttempts: [],
  accessCode: '',
  accessUrl: '',
  createdAt: '',
  updatedAt: '',
  __v: 0,
  uuid: ''
};

const apiPath = `${process.env.VUE_APP_API_BASE_URL}/workspace-ui/auth-bridge/${AuthBridgeActionName.SECURE_CREDENTIAL_ACCESS}`;
const state: BaseItemState<SecureCredentialAccessModel> = {
  items: ref([]),
  selectedItemId: ref(''),
  selectedItem: ref({
    ...emptyItem
  }),
  createdItemId: ref(''),
  createdItem: ref({
    ...emptyItem
  }),
  isLoading: ref(false)
};

// Allows  use of  an "auth bridge" which is an abstraction of "a thing that enables a guest user to validate their identity and access some resource"
// Creating an auth bridge allows you to get a url to provide to the customer to grab the intended resource
export function useSecureCredentialAccess(
  useAuthBridgeQueryParams: any = undefined
): BaseUseItemModel<SecureCredentialAccessModel> & any {
  Vue.$log.debug('Loaded with query', useAuthBridgeQueryParams);

  const { getCurrentOrgQuery } = useAuth();

  const addOrReplaceItemInItems = (newItem: SecureCredentialAccessModel): void => {
    if (state.items.value.some((item: SecureCredentialAccessModel) => item.uuid === newItem.uuid)) {
      state.items.value.forEach((item: SecureCredentialAccessModel, i: number) => {
        if (item.uuid === newItem.uuid) {
          Vue.$log.debug(`Replacing item in items: ${newItem.uuid}`);
          // Note: we must splice instead of assign to trigger render update.
          state.items.value.splice(i, 1, newItem);
        }
      });
    } else {
      Vue.$log.debug(`Adding item to items: ${newItem.uuid}`);
      state.items.value.push(newItem);
    }
    if (newItem?.uuid !== undefined && state.selectedItem?.value?.uuid === newItem?.uuid) {
      Vue.$log.debug(`Replacing selected item: ${newItem.uuid}`);
      state.selectedItem.value = newItem;
    }
  };

  const selectItem = async (uuid: string): Promise<SecureCredentialAccessModel | undefined> => {
    if (!uuid) {
      Object.assign(state.selectedItem, emptyItem);
    }
    Vue.$log.debug(`Selecting item ${uuid} from items: `, state.items.value);
    const item = state.items.value.find((i: SecureCredentialAccessModel) => i.uuid === uuid);
    if (item) {
      state.selectedItemId.value = item?.uuid ? item.uuid : '';
      Vue.$log.debug('Found item', item);
      state.selectedItem.value = item;
      return item;
    }
  };

  const selectItemBy = async (
    key: string,
    value: string
  ): Promise<SecureCredentialAccessModel | undefined> => {
    if (key && value) {
      Vue.$log.debug(`Selecting item ${value} from items: `, state.items.value);
      const item = state.items.value.find((i: any) => i?.[key] === value);
      if (item) {
        state.selectedItemId.value = item?.id ? item.id : '';
        Vue.$log.debug({ selected: item });
        state.selectedItem.value = item;
        return state.selectedItem.value as SecureCredentialAccessModel;
      }
    }
  };
  const getItems = async (query: Record<any, any> | undefined = undefined) => {
    state.isLoading.value = true;
    try {
      const combinedQueryParams = Object.assign({}, useAuthBridgeQueryParams, query, {
        limit: -1
      });
      const res = await axios.get(apiPath, { params: combinedQueryParams });
      Vue.$log.debug('Returned from API: ', res.data);
      // Update the list of items.
      state.items.value = res.data?._embedded ? res.data._embedded : res.data;
      // Update the selected item.
      if (state.selectedItem?.value?.uuid !== undefined) {
        for (const item of state.items.value) {
          if (item?.uuid === state.selectedItem.value.uuid) {
            state.selectedItem.value = item;
          }
        }
      }
      state.isLoading.value = false;
    } catch (err) {
      const errMessage = 'Error loading.';
      Vue.$log.error(errMessage, err);
      state.isLoading.value = false;
      throw new Error(errMessage);
    }
  };

  const sendAccessUrlEmailByItemUuid = async (uuid: string) => {
    state.isLoading.value = true;
    try {
      const res = await axios.post(`${apiPath}/${uuid}/send-email`);
      Vue.$log.debug('Returned from API: ', res.data);
      state.isLoading.value = false;
      return res.data;
    } catch (err) {
      const errMessage = 'Error sending email message.';
      Vue.$log.error(errMessage, err);
      state.isLoading.value = false;
      throw new Error(errMessage);
    }
  };

  const getItem = async (uuid: string) => {
    state.isLoading.value = true;
    if (!uuid) {
      throw new Error('Can not get item without ID');
    }
    try {
      const res = await axios.get<SecureCredentialAccessModel>(`${apiPath}/${uuid}`);
      Vue.$log.debug('Returned from API: ', res.data);
      if (!res?.data?.uuid) {
        Vue.$log.debug('FAIL! uuid is not in response so cannot add item');
        return res.data;
      }
      addOrReplaceItemInItems(res.data);
      state.isLoading.value = false;
      return res.data;
    } catch (err) {
      const errMessage = 'Error loading.';
      Vue.$log.error(errMessage, err);
      state.isLoading.value = false;
      throw new Error(errMessage);
    }
  };

  const validItem = (item: SecureCredentialAccessModel) => {
    return (
      item?.domoSetLoadJobUuid != null &&
      item?.label != null &&
      item?.email != null &&
      item?.usernameToMatch != null &&
      item?.region != null
    );
  };

  const validUpdateItem = async (item: Partial<SecureCredentialAccessModel>) => {
    if (!item.uuid && Object.keys(item).length < 2) {
      const errMessage = 'Item not valid. Need an id to update and at least one field.';
      Vue.$log.error(errMessage, item);
      throw new Error(errMessage);
    }
    return true;
  };

  const fieldsToOmitOnTransaction = [
    'userId',
    'organizationId',
    'state',
    'uuid',
    'createdAt',
    'updatedAt',
    '__v',
    'id',
    'authAttempts',
    'accessCode',
    'accessUrl'
  ];

  const createItem = async (
    item: SecureCredentialAccessModel
  ): Promise<SecureCredentialAccessModel | UserMessageError | undefined> => {
    try {
      Vue.$log.debug('Creating new item: ', item);
      state.isLoading.value = true;
      const body = _.omit(item, fieldsToOmitOnTransaction);
      const res = await axios.post<any>(apiPath, body, {
        headers: { 'content-type': 'application/json' }
      });
      if (res.data) {
        Vue.$log.debug('Successfully created item. Refreshing items.');
        state.createdItem.value = res.data;
        state.createdItemId.value = res.data.uuid;
        return res.data;
      } else {
        const errMessage = 'Invalid response creating item';
        Vue.$log.error(errMessage, res);
        if (res.data?.message !== undefined) {
          throw new UserMessageError(res.data.message);
        }
        throw new UserMessageError('Error on form submission');
      }
    } catch (e) {
      if (e instanceof UserMessageError) {
        throw e;
      }
      // TODO: figure out how we can check for e.response.data.message
      throw new UserMessageError('Error on form submission');
    } finally {
      state.isLoading.value = false;
    }
  };

  const updateItem = async (item: Partial<SecureCredentialAccessModel>) => {
    state.isLoading.value = true;
    if (!(await validUpdateItem(item))) {
      state.isLoading.value = false;
      return undefined;
    }
    Vue.$log.debug('Updating item: ', item);
    try {
      const body = _.omit(item, fieldsToOmitOnTransaction);
      const res = await axios.patch<SecureCredentialAccessModel>(apiPath + '/' + item.uuid, body);
      if (res.data) {
        Vue.$log.debug('Successfully updated item. Refreshing items.');
        addOrReplaceItemInItems(res.data);
        return res.data;
      } else {
        const errMessage = 'Invalid response updating item';
        Vue.$log.error(errMessage, res);
        throw new UserMessageError('Error on form submission.');
      }
    } catch (err) {
      const errMessage = 'Error updating item';
      Vue.$log.error(errMessage, err);
      throw new UserMessageError('Error on form submission.');
    } finally {
      state.isLoading.value = false;
    }
  };

  const deleteItem = async (uuid: string) => {
    if (!uuid) {
      Vue.$log.error('Can not delete item without ID');
      throw new UserMessageError('Cannot delete without an ID.');
    }
    Vue.$log.debug('Deleting item: ', uuid);
    try {
      state.isLoading.value = true;
      const res = await axios.delete<SecureCredentialAccessModel>(`${apiPath}/${uuid}`);
      if (res.data) {
        Vue.$log.debug('Successfully deleted item. Refreshing items.');
      } else {
        Vue.$log.error('Invalid response deleting item: ', res);
        throw new UserMessageError('Error on form submission.');
      }
    } catch (err) {
      Vue.$log.error('Error caught deleting item.', uuid, err);
      throw new UserMessageError('Error on form submission.');
    } finally {
      state.isLoading.value = false;
    }
  };

  /**
   * Refresh the selected item. If it is not selected, select it.
   */
  const refreshItem = async (uuid: string, forceRefresh = false): Promise<void> => {
    Vue.$log.debug(`Refreshing SECURE ACCESS CRED item ${uuid} with force refresh ${forceRefresh}`);
    // First, attempt to select the item if it is not selected.
    if (state.selectedItem?.value?.uuid !== uuid) {
      await selectItem(uuid);
    }
    // Next, if we are forcing refresh or the item is not found (which we infer
    // from an empty selected item), go get it.
    if (uuid && (forceRefresh || state.selectedItem?.value?.uuid !== uuid)) {
      Vue.$log.debug(
        `Refreshing SECURE ACCESS CRED item ${uuid} resulting in re-retrieving item from API`
      );
      await getItem(uuid);
    }
    // Finally, if the selected item doesn't match, select it.
    if (state.selectedItem?.value?.uuid !== uuid) {
      await selectItem(uuid);
    }
  };

  const generateSecureCredentialExportAccessUrl = async (params: SecureCredentialAccessModel) => {
    if (!isValid(params)) {
      Vue.$log.error('Auth bridge parameters are not valid. Make sure all fields are present');
      return;
    }
    return await createItem({ ...params, usernameToMatch: params.usernameToMatch });
  };

  return {
    generateSecureCredentialExportAccessUrl,
    items: computed(() => state.items.value),
    emptyItem: computed(() => emptyItem),
    selectedItem: computed(() => state.selectedItem.value),
    selectedItemId: computed(() => state.selectedItemId),
    createdItemId: computed(() => state.createdItemId),
    createdItem: computed(() => state.createdItem),
    isLoading: state.isLoading,
    selectItemBy,
    getItem,
    getItems,
    createItem,
    updateItem,
    selectItem,
    refreshItem,
    deleteItem,
    sendAccessUrlEmailByItemUuid
  };
}
