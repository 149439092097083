export interface SecureCredentialAccessModel {
  domoSetLoadJobUuid: string;
  usernameToMatch: string;
  email: string;
  label: string;
  region?: string;
  state?: SecureCredentialAccessStateName;
  authAttempts?: any[];
  accessCode?: string;
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
  accessUrl?: string;
  id?: string;
  uuid: string;
}

export enum SecureCredentialAccessStateName {
  DEFAULT = 'NEW',
  NEW = 'NEW',
  SENT = 'SENT',
  CLICKED = 'CLICKED',
  RETRIEVED = 'RETRIEVED'
}
export type ClipboardIconName = 'accessCode' | 'accessUrl';

export enum ClipboardIconColors {
  DEFAULT = 'light',
  CLICKED = 'primary'
}
