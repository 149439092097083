var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("domain-workspace-toolbar", {
        key:
          _vm.getFriendlyLoadJobTypeName(_vm.selectedLoadJob.type) +
          _vm.uuidv4(),
        attrs: {
          backLink: _vm.backLink,
          subtitle:
            _vm.getFriendlyLoadJobTypeName(_vm.selectedLoadJob.type) + " Job"
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("refresh-button", {
                  attrs: { refreshFunction: _vm.refreshItems }
                }),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.isRunButtonDisabled,
                      tile: "",
                      small: ""
                    },
                    on: { click: _vm.confirmGoToRunLoadJobWizard }
                  },
                  [_vm._v(" Run ")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.isModelExpired(_vm.selectedLoadJob)
        ? _c(
            "v-row",
            { staticClass: "mt-8" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "elevation-2",
                      attrs: {
                        type: "error",
                        "colored-border": "",
                        border: "top"
                      }
                    },
                    [
                      _c("b", [_vm._v("EXPIRED")]),
                      _vm._v(
                        ": This Job is no longer available because it is older than " +
                          _vm._s(_vm.DOMO_EXPIRATION_TTL_DAYS) +
                          " days. "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { loading: _vm.isLoading, height: "100%" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("job-status-icon", {
                        attrs: {
                          job: _vm.selectedLoadJob,
                          suffix: _vm.selectedLoadJob.label
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-subtitle",
                    [
                      _vm._v(
                        " Last Updated at " +
                          _vm._s(
                            _vm.formatDbDate(_vm.selectedLoadJob.updatedAt)
                          ) +
                          " "
                      ),
                      _c("job-duration-text", {
                        attrs: { job: _vm.selectedLoadJob }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      (_vm.selectedLoadJob.state === _vm.LoadJobStateName.NEW ||
                        _vm.selectedLoadJob.state ===
                          _vm.LoadJobStateName.READY) &&
                      _vm.isJobOlderThanLatestJob
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "elevation-2",
                              attrs: { type: "error", outlined: "" }
                            },
                            [
                              _vm._v(
                                " This job cannot be run because another job was run before it. This job must be re-created. "
                              )
                            ]
                          )
                        : _vm.selectedLoadJob.state === _vm.LoadJobStateName.NEW
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "elevation-2",
                              attrs: { type: "info", outlined: "" }
                            },
                            [_vm._v(" Wizard setup is not complete. ")]
                          )
                        : _vm.selectedLoadJob.state ===
                          _vm.LoadJobStateName.READY
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "elevation-2",
                              attrs: { type: "info", outlined: "" }
                            },
                            [
                              _vm._v(" Job is ready to be run."),
                              _vm.selectedLoadJob.type !== _vm.LoadJobType.RESET
                                ? _c("span", [
                                    _vm._v(
                                      " Be sure to address fixable issues first!"
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm.selectedLoadJob.state ===
                          _vm.LoadJobStateName.CANCELLED
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "elevation-2",
                              attrs: { type: "info", outlined: "" }
                            },
                            [
                              _vm._v(
                                " This job was cancelled. It cannot be run or modified. "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.selectedLoadJob.state ===
                      _vm.LoadJobStateName.IN_PROGRESS
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-avatar",
                                { attrs: { tile: "", size: "100" } },
                                [
                                  _c(
                                    "v-progress-circular",
                                    {
                                      attrs: {
                                        rotate: 270,
                                        size: 100,
                                        width: 15,
                                        value: _vm.getJobProgressPercentCompletion(
                                          _vm.selectedLoadJob
                                        ),
                                        color: "blue darken-3"
                                      }
                                    },
                                    [
                                      _c("span", { staticClass: "title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getJobProgressPercentCompletion(
                                              _vm.selectedLoadJob
                                            )
                                          ) + "%"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "title mb-1" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatNumber(
                                              _vm.getTotalOperations(
                                                _vm.selectedLoadJob
                                              )
                                            )
                                          ) +
                                          " operations in progress"
                                      )
                                    ]
                                  ),
                                  _c("p", [
                                    _vm._v(" The job is running. "),
                                    _vm.selectedLoadJob.email
                                      ? _c("span", [
                                          _vm._v(
                                            "An email will be sent to " +
                                              _vm._s(
                                                _vm.selectedLoadJob.email
                                              ) +
                                              " on completion."
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectedLoadJob.state === _vm.LoadJobStateName.DONE
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-avatar",
                                { attrs: { tile: "", size: "100" } },
                                [
                                  _c(
                                    "v-progress-circular",
                                    {
                                      attrs: {
                                        rotate: 270,
                                        size: 100,
                                        width: 15,
                                        value: _vm.getTotalSuccessPercent(
                                          _vm.selectedLoadJob
                                        ),
                                        color: "success"
                                      }
                                    },
                                    [
                                      _c("span", { staticClass: "title" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getTotalSuccessPercent(
                                              _vm.selectedLoadJob
                                            )
                                          ) + "%"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "title mb-1" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatNumber(
                                              _vm.getTotalOperationsSucceeded(
                                                _vm.selectedLoadJob
                                              )
                                            )
                                          ) +
                                          " of " +
                                          _vm._s(
                                            _vm.formatNumber(
                                              _vm.getTotalOperations(
                                                _vm.selectedLoadJob
                                              )
                                            )
                                          ) +
                                          " operations succeeded"
                                      )
                                    ]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " The job is complete. Download and review the Job Report for errors. There were " +
                                        _vm._s(
                                          _vm.formatNumber(
                                            _vm.getTotalOperationsFailed(
                                              _vm.selectedLoadJob
                                            )
                                          )
                                        ) +
                                        " errors. "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isModelExpired(_vm.selectedLoadJob)
                        ? _c("div", [
                            _c(
                              "p",
                              { staticClass: "mt-3 mb-2" },
                              [
                                _c("load-job-reports-button", {
                                  attrs: {
                                    loadJob: _vm.selectedLoadJob,
                                    label: " Download reports"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "p",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "accent-4",
                                      text: "",
                                      small: ""
                                    },
                                    on: {
                                      click: _vm.goToLoadJobOperationBrowserPage
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-magnify")
                                    ]),
                                    _vm._v(" Browse Operations ")
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.selectedLoadJob.state ===
                            _vm.LoadJobStateName.IN_PROGRESS
                              ? _c(
                                  "p",
                                  { staticClass: "mb-2" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "error",
                                          text: "",
                                          small: "",
                                          disabled:
                                            _vm.isCanceling ||
                                            _vm.isModelExpired(
                                              _vm.selectedLoadJob
                                            )
                                        },
                                        on: { click: _vm.runCancelLoadJob }
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("mdi-close-circle")
                                        ]),
                                        _vm._v(" Cancel ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedLoadJob.state ===
                              _vm.LoadJobStateName.DONE &&
                            _vm.selectedLoadJob.type !== _vm.LoadJobType.RESET
                              ? _c(
                                  "p",
                                  { staticClass: "mb-2" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "accent-4",
                                          text: "",
                                          small: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.showSecureCredentialAccessForm(
                                              _vm.selectedLoadJob
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("mdi-lock")
                                        ]),
                                        _vm._v(" Secure Credential Access ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "mb-2", attrs: { height: "100%" } },
                [
                  _c("v-card-title", [_vm._v("Validation Issues")]),
                  _vm.selectedLoadJob.type === _vm.LoadJobType.RESET
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c(
                                  "span",
                                  { staticClass: "ml-2" },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "success" } },
                                      [_vm._v("mdi-check-circle")]
                                    ),
                                    _vm._v(" No Issues Found")
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-card-text",
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              attrs: { color: "error", "text-color": "white" }
                            },
                            [
                              _c("v-badge", {
                                attrs: {
                                  inline: "",
                                  left: "",
                                  color: "error darken-4",
                                  content: String(
                                    _vm.getLastValidationJobSummaryCount(
                                      _vm.selectedLoadJob,
                                      _vm.ValidationSummaryCountType.ERROR
                                    )
                                  )
                                }
                              }),
                              _vm._v(" Errors ")
                            ],
                            1
                          ),
                          _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              attrs: { color: "warning", "text-color": "white" }
                            },
                            [
                              _c("v-badge", {
                                attrs: {
                                  inline: "",
                                  left: "",
                                  color: "warning darken-4",
                                  content: String(
                                    _vm.getLastValidationJobSummaryCount(
                                      _vm.selectedLoadJob,
                                      _vm.ValidationSummaryCountType.WARNING
                                    )
                                  )
                                }
                              }),
                              _vm._v(" Warnings ")
                            ],
                            1
                          ),
                          _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              attrs: { color: "blue", "text-color": "white" }
                            },
                            [
                              _c("v-badge", {
                                attrs: {
                                  inline: "",
                                  left: "",
                                  color: "blue darken-4",
                                  content: String(
                                    _vm.getLastValidationJobSummaryCount(
                                      _vm.selectedLoadJob,
                                      _vm.ValidationSummaryCountType.NOTICE
                                    )
                                  )
                                }
                              }),
                              _vm._v(" Notices ")
                            ],
                            1
                          ),
                          _c("br"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled:
                                  !_vm.isJobPermitted ||
                                  _vm.isModelExpired(_vm.selectedLoadJob),
                                color: "primary",
                                large: "",
                                plain: "",
                                title:
                                  "A job can only be fixed if it has not yet been run."
                              },
                              on: { click: _vm.goToFixImportJobPage }
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-arrow-right")]),
                              _vm._v(" Fix ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.selectedLoadJob.type === "IMPORT"
        ? _c(
            "v-row",
            { staticClass: "mb-2" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { height: "100%", isLoading: _vm.isLoading } },
                    [
                      _c("v-card-title", [_vm._v("Source")]),
                      _c(
                        "v-card-text",
                        [
                          _vm.isSourceDomainMonolith
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "elevation-2",
                                  attrs: {
                                    type: "error",
                                    "colored-border": "",
                                    border: "top"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.BAD_FIVE9_VERSION_MESSAGE) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c("p", [
                            _c(
                              "span",
                              { staticClass: "overline font-weight-black" },
                              [_vm._v("Domain")]
                            ),
                            _c("br"),
                            _c("span", { staticClass: "body-2" }, [
                              _vm._v(_vm._s(_vm.sourceDomain.label))
                            ])
                          ]),
                          _vm.sourceExportJob && _vm.sourceExportJob.label
                            ? _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "overline font-weight-black" },
                                  [_vm._v("Snapshot")]
                                ),
                                _c("br"),
                                _c("span", { staticClass: "body-2" }, [
                                  _vm._v(_vm._s(_vm.sourceExportJob.label))
                                ]),
                                _c("br"),
                                _c(
                                  "span",
                                  { staticClass: "body-2" },
                                  [
                                    _c("job-status-icon", {
                                      attrs: {
                                        job: _vm.sourceExportJob,
                                        suffix:
                                          _vm.formatNumber(
                                            _vm.getTotalObjects(
                                              _vm.sourceExportJob
                                            )
                                          ) + " objects at "
                                      }
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatDbDate(
                                            _vm.sourceExportJob.updatedAt
                                          )
                                        ) +
                                        " "
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { height: "100%", isLoading: _vm.isLoading } },
                    [
                      _c("v-card-title", [_vm._v("Destination")]),
                      _c(
                        "v-card-text",
                        [
                          _vm.isDestinationDomainMonolith
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "elevation-2",
                                  attrs: {
                                    type: "error",
                                    "colored-border": "",
                                    border: "top"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.BAD_FIVE9_VERSION_MESSAGE) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c("p", [
                            _c(
                              "span",
                              { staticClass: "overline font-weight-black" },
                              [_vm._v("Domain")]
                            ),
                            _c("br"),
                            _c("span", { staticClass: "body-2" }, [
                              _vm._v(_vm._s(_vm.selectedDomain.label))
                            ])
                          ]),
                          _vm.selectedExportJob && _vm.selectedExportJob.label
                            ? _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "overline font-weight-black" },
                                  [_vm._v("Snapshot")]
                                ),
                                _c("br"),
                                _c("span", { staticClass: "body-2" }, [
                                  _vm._v(_vm._s(_vm.selectedExportJob.label))
                                ]),
                                _c("br"),
                                _c(
                                  "span",
                                  { staticClass: "body-2" },
                                  [
                                    _c("job-status-icon", {
                                      attrs: {
                                        job: _vm.selectedExportJob,
                                        suffix:
                                          _vm.formatNumber(
                                            _vm.getTotalObjects(
                                              _vm.selectedExportJob
                                            )
                                          ) + " objects at "
                                      }
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatDbDate(
                                            _vm.selectedExportJob.updatedAt
                                          )
                                        ) +
                                        " "
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "overline font-weight-black" },
                                  [_vm._v("Snapshot")]
                                ),
                                _c("br"),
                                _c("span", { staticClass: "body-2" }, [
                                  _vm._v(
                                    "This is not a Synchronized Import, so operations will be done on the Domain without comparing against a known state."
                                  )
                                ])
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.selectedLoadJob.type === "RESET"
        ? _c(
            "v-row",
            { staticClass: "mb-2" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { height: "100%", loading: _vm.isLoading } },
                    [
                      _c("v-card-title", [_vm._v("Reset Domain")]),
                      _c("v-card-text", [
                        _c("p", [
                          _c(
                            "span",
                            { staticClass: "overline font-weight-black" },
                            [_vm._v("Domain")]
                          ),
                          _c("br"),
                          _c("span", { staticClass: "body-2" }, [
                            _vm._v(_vm._s(_vm.selectedDomain.label))
                          ])
                        ]),
                        _vm.selectedExportJob && _vm.selectedExportJob.label
                          ? _c("div", [
                              _c(
                                "span",
                                { staticClass: "overline font-weight-black" },
                                [_vm._v("Snapshot")]
                              ),
                              _c("br"),
                              _c("span", { staticClass: "body-2" }, [
                                _vm._v(_vm._s(_vm.selectedExportJob.label))
                              ]),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "body-2" },
                                [
                                  _c("job-status-icon", {
                                    attrs: {
                                      job: _vm.selectedExportJob,
                                      suffix:
                                        _vm.formatNumber(
                                          _vm.getTotalObjects(
                                            _vm.selectedExportJob
                                          )
                                        ) + " objects at "
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDbDate(
                                          _vm.selectedExportJob.updatedAt
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.selectedLoadJob.type === "RESTORE"
        ? _c(
            "v-row",
            { staticClass: "mb-2" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { height: "100%", loading: _vm.isLoading } },
                    [
                      _c("v-card-title", [_vm._v("Source")]),
                      _c("v-card-subtitle", [_vm._v("Restore Point")]),
                      _c("v-card-text", [
                        _c("p", [
                          _c(
                            "span",
                            { staticClass: "overline font-weight-black" },
                            [_vm._v("Domain")]
                          ),
                          _c("br"),
                          _c("span", { staticClass: "body-2" }, [
                            _vm._v(_vm._s(_vm.sourceDomain.label))
                          ])
                        ]),
                        _vm.sourceExportJob && _vm.sourceExportJob.label
                          ? _c("div", [
                              _c(
                                "span",
                                { staticClass: "overline font-weight-black" },
                                [_vm._v("Snapshot")]
                              ),
                              _c("br"),
                              _c("span", { staticClass: "body-2" }, [
                                _vm._v(_vm._s(_vm.sourceExportJob.label))
                              ]),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "body-2" },
                                [
                                  _c("job-status-icon", {
                                    attrs: {
                                      job: _vm.sourceExportJob,
                                      suffix:
                                        _vm.formatNumber(
                                          _vm.getTotalObjects(
                                            _vm.sourceExportJob
                                          )
                                        ) + " objects at "
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDbDate(
                                          _vm.sourceExportJob.updatedAt
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { height: "100%", isLoading: _vm.isLoading } },
                    [
                      _c("v-card-title", [_vm._v("Destination")]),
                      _c("v-card-subtitle", [_vm._v("Current State")]),
                      _c("v-card-text", [
                        _c("p", [
                          _c(
                            "span",
                            { staticClass: "overline font-weight-black" },
                            [_vm._v("Domain")]
                          ),
                          _c("br"),
                          _c("span", { staticClass: "body-2" }, [
                            _vm._v(_vm._s(_vm.selectedDomain.label))
                          ])
                        ]),
                        _vm.selectedExportJob && _vm.selectedExportJob.label
                          ? _c("div", [
                              _c(
                                "span",
                                { staticClass: "overline font-weight-black" },
                                [_vm._v("Snapshot")]
                              ),
                              _c("br"),
                              _c("span", { staticClass: "body-2" }, [
                                _vm._v(_vm._s(_vm.selectedExportJob.label))
                              ]),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "body-2" },
                                [
                                  _c("job-status-icon", {
                                    attrs: {
                                      job: _vm.selectedExportJob,
                                      suffix:
                                        _vm.formatNumber(
                                          _vm.getTotalObjects(
                                            _vm.selectedExportJob
                                          )
                                        ) + " objects at "
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDbDate(
                                          _vm.selectedExportJob.updatedAt
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(" Provisioning Comparison ")
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("import-job-detail-provisioning-comparison", {
                            attrs: {
                              sourceExportJob: _vm.sourceExportJob,
                              sourceDomain: _vm.sourceDomain,
                              destinationDomain: _vm.selectedDomain
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [_vm._v(" Advanced ")]),
                      _c("v-expansion-panel-content", [
                        _vm.isModelExpired(_vm.selectedLoadJob)
                          ? _c("div", [
                              _vm._v(
                                " You cannot access advanced options if the job has expired. "
                              )
                            ])
                          : _c(
                              "div",
                              [
                                _c(
                                  "v-alert",
                                  {
                                    attrs: {
                                      type: "error",
                                      color: "red darken-4"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "Warning! Modifying which operations to run may cause adverse side effects or cascading failures. Please use caution."
                                    )
                                  ]
                                ),
                                _c("domo-operation-changer", {
                                  attrs: {
                                    jobUuid: _vm.jobUuid,
                                    summary: _vm.isJobPermitted ? false : true
                                  }
                                })
                              ],
                              1
                            )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.userCanUseTransformJobs(_vm.assertionContext.roles)
        ? _c(
            "v-row",
            [
              _c("v-col", [
                _c("h1", [_vm._v("Operation Transform Jobs")]),
                _vm.isModelExpired(_vm.selectedLoadJob)
                  ? _c("div", [
                      _vm._v(
                        " You cannot access advanced options if the job has expired. "
                      )
                    ])
                  : _c(
                      "div",
                      [
                        _c("p", [
                          _vm._v(
                            " You have access to an advanced feature that lets you modify the operations in a highly advanced way using JSON patches. By checking the box below you will get access to this advanced feature, but you also are agreeing to take on the risks with using this feature. "
                          )
                        ]),
                        _c("v-checkbox", {
                          attrs: {
                            label:
                              "I agree that I take full ownership of any failures caused by using this feature and vow to only use it if I understand the DOMO data structure and JSON Patches.",
                            name: "isOperationCurationStationDisclaimerAgreed"
                          },
                          model: {
                            value:
                              _vm.isOperationCurationStationDisclaimerAgreed,
                            callback: function($$v) {
                              _vm.isOperationCurationStationDisclaimerAgreed = $$v
                            },
                            expression:
                              "isOperationCurationStationDisclaimerAgreed"
                          }
                        }),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: !_vm.isOperationCurationStationDisclaimerAgreed,
                              color: "error"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.isOperationCurationStationDisclaimerAgreed,
                                    expression:
                                      "isOperationCurationStationDisclaimerAgreed"
                                  }
                                ],
                                on: { click: _vm.goToTransformJobPage }
                              },
                              [
                                _vm._v(" Take me to funky town! "),
                                _c(
                                  "v-icon",
                                  { staticClass: "ml-4", attrs: { left: "" } },
                                  [_vm._v(" mdi-music ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isOperationCurationStationDisclaimerAgreed,
                                    expression:
                                      "!isOperationCurationStationDisclaimerAgreed"
                                  }
                                ]
                              },
                              [_vm._v("Take me to...")]
                            )
                          ]
                        )
                      ],
                      1
                    )
              ])
            ],
            1
          )
        : _vm._e(),
      _c("confirm"),
      _c("secure-credential-access-form")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }